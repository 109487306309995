import './Doc.css';
import {RedocStandalone} from 'redoc';
import {Colors} from "../../colors";

const ApiDocPayguard = () => {
    return (
        <div className="App">
            <RedocStandalone specUrl="https://fusiontelecom-api-docs.s3.eu-west-2.amazonaws.com/PayGuardOpenApi.yaml"
                             options={{
                                 nativeScrollbars: true,
                                 // jsonSampleExpandLevel: 'all',
                                 hideDownloadButton: true,
                                 theme: {
                                     typography: {
                                         fontFamily: "'Open sans', sans-serif",
                                         code: {
                                             fontSize: "13px",
                                             fontFamily: "'Open sans', sans-serif",
                                             wrap: true
                                         },
                                     },
                                     menu: {
                                         textColor: Colors.midnight_blue,
                                         backgroundColor: Colors.blue_haze_light_shade,
                                         width: "15%",
                                     },
                                     rightPanel: {
                                         backgroundColor: Colors.blue_haze_light_shade,
                                         textColor: Colors.greys_black,
                                     }
                                 }

                             }}

            />
        </div>
    );
}

export default ApiDocPayguard;
