export const Colors = {
    midnight_blue_dark_shade: '#2D2C45',
    midnight_blue: '#2B295E',
    midnight_blue_mid_shade: '#C4C2F2',
    midnight_blue_light_shade: '#F0F0FF',

    blue_haze_dark_shade: '#26415C',
    blue_haze: '#70A7DB',
    blue_haze_mid_shade: '#B3D4F2',
    blue_haze_light_shade: '#E8F4FF',

    pink_dark_shade: '#591542',
    pink: '#BF087F',
    pink_mid_shade: '#F2B3DC',
    pink_light_shade: '#FFF0FA',

    greys_black: '#1A1A1A',
    greys_dark_shade: '#4D4D4D',
    greys_mid_shade: '#B3B3B3',
    greys_light_shade: '#F2F2F2',

    transparent:'rgba(0, 0, 0, 0)',
    white:'#FFFFFF',
}
