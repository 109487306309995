import './App.css';
import React from "react";
import ApiDocPayguard from "./pages/api-docs/ApiDocPayguard";

function App() {
    return (
        <div>
            <ApiDocPayguard/>
        </div>
    );
}

export default App